.footnotes li {
  font-size: 0.8em;
  margin-bottom: 0.5em;
  color: #999999;
}

.footnotes p {
  margin-bottom: 0.5em;
}

.footnotes hr {
  display: none;
}
